import React, { useEffect } from "react";
import { Table, Container, Row } from "react-bootstrap";
import * as BiIcons from "react-icons/bi";
import { RiMessageFill } from "react-icons/ri";
import "../../styles/app.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import Button from "@mui/material/Button";
import DashBanner from "../../components/dash_banner";
import GenericSideBar from "../../components/sidebar/GenericSideBar";

function PatientReport() {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    axios
      .get("https://novid390.herokuapp.com/getPatients?assignedDoctor=" + Cookies.get("userid"))
      .then(response => setStats(response.data));
  }, []);

  return (
    <>
      <GenericSideBar />

      <div className="page-container">
        <Container fluid>
          <Row>
            <DashBanner PageName="Patient Report"></DashBanner>
          </Row>
        </Container>

        <div>
          <Table hover>
            <thead>
              <tr>
                <th>Last Name</th>
                <th>First Name</th>
                <th>Tested Positive</th>
                <th>Message Patient</th>
                <th>Set Appointment</th>
                <th>View Patient</th>
              </tr>
            </thead>

            <tbody>
              {stats.map((data, index) => (
                <tr key={index}>
                  <td> {data.lastname} </td>
                  <td> {data.firstname} </td>
                  <td> {data.testedPositive ? "YES" : "NO"} </td>
                  <td>
                    <Link to={"/messenger"}>
                      <RiMessageFill size={21} /> Message {data.firstname}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/schedule-appointment/${data._id}`}>
                      <Button
                        style={{
                          borderRadius: 35,
                          backgroundColor: "#21b6ae",
                        }}
                        variant="contained"
                        size="small"
                        disableElevation>
                        Set Appointment
                      </Button>
                    </Link>
                  </td>
                  <td className="Patient-Report-Actions">
                    <Link to={`/patient-status-history/${data._id}`}>
                      <BiIcons.BiSearchAlt size={21} /> View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default PatientReport;
