import React from "react";
import { Col, Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";

function Email(props) {
  return (
    <Col>
      <Form.Group controlId="">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="example@example.com"
          {...props.register("email", {
            pattern: {
              value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Invalid email format",
            },
            required: { value: true, message: "This field is required" },
          })}
        />
        <ErrorMessage
          errors={props.errors}
          name="email"
          render={({ message }) => <div style={{ color: "red" }}>{message}</div>}
        />
      </Form.Group>
    </Col>
  );
}
export default Email;
