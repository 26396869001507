import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";

function FirstNameLastNameFields(props) {
  return (
    <Col>
      <Row>
        <Col md={6}>
          <Form.Group controlId="">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              placeholder="First Name"
              type="text"
              {...props.register("firstname", {
                required: { value: true, message: "This field is required" },
              })}
            />
            <ErrorMessage
              errors={props.errors}
              name="firstname"
              render={({ message }) => <div style={{ color: "red" }}>{message}</div>}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              placeholder="Last Name"
              type="text"
              {...props.register("lastname", {
                required: { value: true, message: "This field is required" },
              })}
            />
            <ErrorMessage
              errors={props.errors}
              name="lastname"
              render={({ message }) => <div style={{ color: "red" }}>{message}</div>}
            />
          </Form.Group>
        </Col>
      </Row>
    </Col>
  );
}
export default FirstNameLastNameFields;
