import "../../styles/app.css";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/individuals_contact_tracing_form.css";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import DashBanner from "../../components/dash_banner";
import axios from "axios";
import GenericSideBar from "../../components/sidebar/GenericSideBar";

function ContactReportHistory() {
  const [formName] = useState("Individual Contact Tracing Form");
  const [showSuccess, setSuccess] = useState(false);
  const [reportInfo, setReport] = useState("");
  const [reportid] = useState(useParams());

  useEffect(() => {
    axios
      .get("https://novid390.herokuapp.com/getIndividualContact?_id=" + reportid._id)
      .then(response => response.data)
      .then(response => setReport(response[0]))
      .catch(err => console.log(err));
  }, [reportInfo, reportid._id]);

  //Axios way of accpeting many get requests
  const onSubmit = async () => {
    await axios
      .all([
        axios.get("https://novid390.herokuapp.com/sendMessage?phoneNumber=" + reportInfo.phoneNumber),
        axios.get(`https://novid390.herokuapp.com/sendEmail?email=${reportInfo.email}`),
        setSuccess(true),
      ])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];

          console.log(responseOne, responseTwo);
        })
      )
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <DashBanner typeOfForm={formName} />

        <Row>
          <Col lg={6}>
            <Card id="individualConctactCard">
              <Card.Body className="cardBoxes">
                <Form id="individualContactForm">
                  <Form.Group>
                    <Row>
                      <Form.Label className="nameTitles">Individual Form</Form.Label>
                      <Form.Label className="nameTitles">Name</Form.Label>

                      <Col lg={6}>
                        <Form.Control type="text" placeholder={reportInfo.firstName} disabled />
                      </Col>
                      <Col lg={6}>
                        <Form.Control type="text" placeholder={reportInfo.lastName} disabled />
                      </Col>
                    </Row>

                    <Form.Label className="nameTitles">Email</Form.Label>
                    <Form.Control type="text" placeholder={reportInfo.email} disabled />

                    <Form.Label className="nameTitles"> Phone Number</Form.Label>
                    <Form.Control type="tel" placeholder={reportInfo.phoneNumber} disabled />

                    <Form.Label className="nameTitles"> Date Of Contact </Form.Label>
                    <Form.Control type="text" placeholder={reportInfo.date} disabled />
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
            <Button
              onClick={() => onSubmit()}
              type="submit"
              variant="flat"
              className="buttonColor submit_contact_form_button">
              Notify
            </Button>
            {showSuccess ? (
              <Form.Label className="nameTitles">Individual contact successfully notified.</Form.Label>
            ) : null}
            <br />
          </Col>

          <Col></Col>
        </Row>
      </div>
    </>
  );
}

export default ContactReportHistory;
