import "./styles/app.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import login from "./components/login";
import Dashboard from "./pages/dashboard";
import register from "./components/register";
import EmployeeRegistration from "./pages/employee_registration";
import PatientRegistration from "./pages/patient_registration";

import Messenger from "./pages/messenger/messenger";
import Appointments from "./pages/doctor/appointments";
import PatientStatusHistory from "./pages/doctor/patient_status_history";
import PatientOverview from "./pages/doctor/patient_overview";
import PatientReport from "./pages/doctor/patient_report";
import ScheduleAppointment from "./pages/doctor/appointment_scheduler";
import Status_History from "./pages/patient/status_history";

import ContactReportHistory from "./pages/healthofficial/contact_report_history";
import ContactReport from "./pages/healthofficial/contact_report";
import IndividualContactTracingForm from "./pages/patient/individuals_contact_tracing_forms";
import EventContactTracingForm from "./pages/patient/event_contact_tracing_form";
import SelectContactTracingForm from "./pages/patient/select_contact_tracing_form";
import UpdateStatus from "./pages/patient/update_status";
import StatusOverview from "./pages/patient/status_overview";
import Add_Status from "./pages/patient/add_status";
import PatientsAppoinments from "./pages/patient/patient_appointment";

//Immigration Officer Imports
import ImmigrationSearch from "./pages/immigraton_officer/immigration_officer_search";
import PatientMostRecentReport from "./pages/immigraton_officer/immigration_patient_most_recent_report";
//Admin Pages
import ViewUserAdmin from "./pages/adminuser/AdminViewUsers";
import EditAssignedDoctor from "./pages/adminuser/edit_assigned_doctor";
import CreateUser from "./pages/adminuser/CreateUser";
// react-bootstrap components
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <Router>
        <Switch>
          {/* Login and Registration Pages */}
          <Route path="/employeeReg" exact component={EmployeeRegistration} />
          <Route path="/patientReg" exact component={PatientRegistration} />
          <Route path="/register" exact component={register} />
          <Route path="/login" exact component={login} />
          <Route path="/" exact component={login} />
          {/* ------ Doctor Pages ------ */}
          <Route path="/" exact component={Dashboard} />
          <Route path="/messenger" component={Messenger} />
          <Route path="/status-history" component={Status_History} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/appointments" component={Appointments} />
          <Route path="/patient-status-history/:_id" component={PatientStatusHistory} />
          <Route path="/patient-overview/:_id" exact component={PatientOverview} />
          <Route path="/patient-report" exact component={PatientReport} />
          <Route path="/schedule-appointment/:patient_id" exact component={ScheduleAppointment} />
          {/*--------------Health Official Routing----------- */}
          <Route path="/contact_report" component={ContactReport} />
          <Route path="/contact-report-history/:_id" component={ContactReportHistory} />
          {/*--------------Contact Tracing Routing----------- */}
          <Route path="/contact_tracing_individual" component={IndividualContactTracingForm} />
          <Route path="/contact_tracing_event" component={EventContactTracingForm} />
          <Route path="/contact_tracing_selector" component={SelectContactTracingForm} />
          {/* ------ Patient Pages ------ */}
          <Route path="/add-status" component={Add_Status} />
          <Route path="/status-history" component={Status_History} />
          <Route path="/status-overview/:_id" component={StatusOverview} />
          <Route path="/update-status/:_id" component={UpdateStatus} />
          <Route path="/patient-appointments" component={PatientsAppoinments} />
          {/* ----Immigration Officer--- */}
          <Route path="/search-patient" component={ImmigrationSearch} />
          <Route path="/patient-most-recent-report/:_id" component={PatientMostRecentReport} />
          {/* ------ Admin Pages ------ */}
          <Route path="/view-users-admin" component={ViewUserAdmin} />
          <Route path="/edit_assigned_doctor/:patient_id" component={EditAssignedDoctor} />
          <Route path="/create-user-admin" component={CreateUser} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
