import { Col, Form, Button, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/individuals_contact_tracing_form.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import GenericSideBar from "../../components/sidebar/GenericSideBar";
import DashBanner from "../../components/dash_banner";
function SelectContactTracingForm() {
  const [formName] = useState("Contact Tracing Form");
  const [formSelected, setFormSelected] = useState("");

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <DashBanner PageName={formName} />
        <Col lg={6}>
          <Card>
            <Card.Body className="cardBoxes">
              <Form as={Col}>
                <Form.Label className="nameTitles">Select Form Type</Form.Label>
                <Form.Select value={formSelected} onChange={e => setFormSelected(e.target.value)}>
                  <option>Choose...</option>
                  <option value="/contact_tracing_individual">Individual Contact Tracing Form</option>
                  <option value="/contact_tracing_event">Event/Store Contact Tracing Form</option>
                </Form.Select>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <br></br>
        <Link to={formSelected}>
          <Button variant="flat" className="buttonColor submit_contact_form_button">
            Submit
          </Button>
        </Link>
      </div>
    </>
  );
}

export default SelectContactTracingForm;
