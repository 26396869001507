import DashBanner from "../../components/dash_banner";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import GenericSideBar from "../../components/sidebar/GenericSideBar";
import Cookies from "js-cookie";
function ImmigrationDash() {
  const [allPatients, setAllPatients] = useState([]);
  const [patientId, setPatientId] = useState("");
  const [userRole] = useState(Cookies.get("role"));
  useEffect(() => {
    axios.get("https://novid390.herokuapp.com/getUsers?Roles=Patient").then(response => setAllPatients(response.data));
  });

  const referenceLink =
    userRole === "Immigration Officer" || userRole === "Health Official"
      ? `/patient-most-recent-report/${patientId}`
      : `/edit_assigned_doctor/${patientId}`;

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <DashBanner PageName="Search" />
        <Col lg={5}>
          <Autocomplete
            onChange={(event, value) => setPatientId(value.split(" ")[3])}
            freeSolo
            id="search"
            disableClearable
            options={allPatients.map(patient => `${patient.firstname} ${patient.lastname} ID: ${patient._id}`)}
            renderInput={patientNames => (
              <TextField
                {...patientNames}
                label="Search Patient"
                InputProps={{
                  ...patientNames.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          <Link to={referenceLink}>
            <Button variant="flat" className="buttonColor" style={{ marginTop: 10 }}>
              Submit
            </Button>
          </Link>
        </Col>
      </div>
    </>
  );
}

export default ImmigrationDash;
