import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";

function AddressGenderNumber(props) {
  return (
    <Row>
      <Col>
        <fieldset>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label as="legend" column sm={2}>
                Address
              </Form.Label>
              <Col>
                <Form.Group controlId="">
                  <Form.Label>Street Address 1</Form.Label>
                  <Form.Control
                    placeholder=""
                    type="text"
                    {...props.register("Address", {
                      required: { value: true, message: "This field is required" },
                    })}
                  />
                  <ErrorMessage
                    errors={props.errors}
                    name="Address"
                    render={({ message }) => <div style={{ color: "red" }}>{message}</div>}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Row style={{ marginTop: "25px" }}>
                  <Col md={6}>
                    <Form.Group controlId="">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        placeholder=""
                        type="text"
                        {...props.register("City", {
                          required: { value: true, message: "This field is required" },
                        })}
                      />
                      <ErrorMessage
                        errors={props.errors}
                        name="City"
                        render={({ message }) => <div style={{ color: "red" }}>{message}</div>}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="">
                      <Form.Label>Province</Form.Label>
                      <Form.Control
                        placeholder=""
                        type="text"
                        {...props.register("Province", {
                          required: { value: true, message: "This field is required" },
                        })}
                      />
                      <ErrorMessage
                        errors={props.errors}
                        name="Province"
                        render={({ message }) => <div style={{ color: "red" }}>{message}</div>}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Col>
        </fieldset>
      </Col>
      <Col>
        <fieldset>
          <Form.Group as={Col} className="mb-3">
            <Form.Label as="legend" column sm={2}>
              Gender
            </Form.Label>
            <Col sm={10}>
              <Form.Check type="radio" label="Male" name="Gender" value="M" id="" {...props.register("Gender")} />
              <Form.Check type="radio" label="Female" name="Gender" value="F" id="" {...props.register("Gender")} />
              <Form.Check type="radio" label="Other" name="Gender" value="NON" id="" {...props.register("Gender")} />
            </Col>
          </Form.Group>
        </fieldset>
        <Col>
          <Form.Group controlId="phoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              placeholder="(000) 000-0000"
              type="tel"
              {...props.register("phonenumber", {
                pattern: {
                  value: /^\d{10,11}$/,
                  message: "Phone number should be ten or eleven digits with no symbols",
                },
                required: { value: true, message: "This field is required" },
              })}
            />
            <ErrorMessage
              errors={props.errors}
              name="phonenumber"
              render={({ message }) => <div style={{ color: "red" }}>{message}</div>}
            />
          </Form.Group>
        </Col>
      </Col>
    </Row>
  );
}
export default AddressGenderNumber;
