import React, { useEffect, useState } from "react";
import axios from "axios";

import "../../styles/messenger.css";
import defaultAvatar from "../../assets/default-avatar.jpg";

function Conversation({ conversation, currentUser }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    //gets all users who have a convo with currentuser
    const receiveId = conversation.users.find(mem => mem !== currentUser);

    //console.log(receiveId)

    const getUser = async () => {
      try {
        const res = await axios.post("https://novid390.herokuapp.com/getUserById", { userid: receiveId });
        //console.log(res)
        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getUser();
  }, [currentUser, conversation]);

  return (
    <div className="conversation">
      <img className="conversationImg" src={defaultAvatar} alt="" />
      <span className="conversationName">
        {user?.firstname} {user?.lastname}
      </span>
    </div>
  );
}

export default Conversation;
