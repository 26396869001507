import React from "react";
import { Col, Form } from "react-bootstrap";

function ContactPreference(props) {
  return (
    <fieldset>
      <Form.Group as={Col} className="mb-6">
        <Form.Label as="legend" column sm={6}>
          Contact (Select all that apply)
        </Form.Label>
        <Col sm={10}>
          <Form.Check
            type="checkbox"
            label="I want to be contacted by email"
            name="Contact"
            id=""
            value="EMAIL"
            {...props.register("Contact")}
          />
          <Form.Check
            type="checkbox"
            label="I want to be contacted by text message"
            name="Contact"
            id=""
            value="SMS"
            {...props.register("Contact")}
          />
        </Col>
      </Form.Group>
    </fieldset>
  );
}
export default ContactPreference;
