import { Col, Form, Row } from "react-bootstrap";

function TestedPostive({ covidIdentifier }) {
  return (
    <>
      <label className="inline TestedPostive">Tested Positive?</label>
      <Row>
        <Col md={3}>
          <Form.Check
            name="testedPositive"
            type="radio"
            label="Yes"
            {...(covidIdentifier === true ? { checked: "true" } : "")}
            disabled
          />
        </Col>
        <Col md={3}>
          <Form.Check
            name="testedPositive"
            type="radio"
            label="No"
            {...(covidIdentifier === false ? { checked: "true" } : "")}
            disabled
          />
        </Col>
      </Row>
    </>
  );
}

export default TestedPostive;
