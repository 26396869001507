import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import DashBanner from "../../components/dash_banner";
import GenericSideBar from "../../components/sidebar/GenericSideBar";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

function EditAssignedDoctor() {
  //Hooks
  const { patient_id } = useParams();
  const [allDoctors, setAllDoctors] = useState([]);
  const [patientName, setPatientName] = useState("");
  const [selectedDoctorId, setSelectedDoctorId] = useState("");
  const history = useHistory();

  //Onload
  useEffect(() => {
    getPatientName();
    getAllDoctors();
  });

  const getPatientName = async () => {
    let promise = await fetch(`https://novid390.herokuapp.com/getUsers?_id=${patient_id}`);
    let user_info = await promise.json();
    setPatientName(`${user_info[0].firstname} ${user_info[0].lastname}`);
  };

  const getAllDoctors = async () => {
    let promise = await fetch(`https://novid390.herokuapp.com/getUsers?Roles=Doctor`);
    let arrayOfDoctors = await promise.json();
    Promise.all(arrayOfDoctors).then(results => {
      setAllDoctors(results);
    });
  };

  const assignDoctor = () => {
    axios.put(`https://novid390.herokuapp.com/put_assign_new_doctor?patientId=${patient_id}`, {
      assignedDoctor: selectedDoctorId,
    });
    history.push("/view-users-admin");
  };

  // creates a conversation between the selected patient and doctor on submit
  const createConversation = async () => {
    try {
      await axios.post("https://novid390.herokuapp.com/conversations", {
        senderId: patient_id,
        receiverId: selectedDoctorId,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <Container fluid>
          <Row>
            <DashBanner PageName="Assign Doctor"></DashBanner>
          </Row>
        </Container>
        <Col lg={6}>
          <Card>
            <Card.Body className="cardBoxes">
              <Row>
                <Col lg={8}>
                  <Card.Header>
                    <Card.Title className="nameTitles " as="h4">
                      Patient Name <br />
                    </Card.Title>
                    {patientName}
                  </Card.Header>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card.Title className="nameTitles " as="h4">
                    Select Doctor
                  </Card.Title>
                  <FormControl fullWidth>
                    <Autocomplete
                      onChange={(event, value) => setSelectedDoctorId(value.split(" ")[3])}
                      freeSolo
                      id="search"
                      disableClearable
                      options={allDoctors.map(doctor => `${doctor.firstname} ${doctor.lastname} ID: ${doctor._id}`)}
                      renderInput={doctorNames => (
                        <TextField
                          {...doctorNames}
                          label="Search Doctor"
                          InputProps={{
                            ...doctorNames.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Button
          variant="flat"
          className="buttonColor"
          style={{ marginTop: 10 }}
          onClick={() => {
            assignDoctor();
            createConversation();
          }}>
          Submit
        </Button>
      </div>
    </>
  );
}

export default EditAssignedDoctor;
