import axios from "axios";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

function VirtualAppointmentReminder({ userid }) {
  const [firstAppointment, setFirstAppointment] = useState("");

  useEffect(() => {
    getAndSetFirstAppointment();
  });

  const getAndSetFirstAppointment = async () => {
    //Getting the First Appointment
    let firstAppointment = await axios.get(
      "https://novid390.herokuapp.com/get_closest_appointment?doctor_id=" + userid
    );
    //Adding the Patients name to the appointment
    let UpdatedfirstAppointment = await firstAppointment.data.map(async appointment =>
      addNamesAndTimeToAppointment(appointment)
    );
    //Setting the first Appointment
    Promise.all(UpdatedfirstAppointment).then(appoinments => {
      setFirstAppointment(appoinments[0]);
    });
  };

  const addNamesAndTimeToAppointment = async appointment => {
    let user = await fetch("https://novid390.herokuapp.com/getPatients?_id=" + appointment.patient_id);
    let jsondUsers = await user.json();
    appointment.firstName = jsondUsers[0].firstname;
    appointment.lastName = jsondUsers[0].lastname;
    appointment.appointment_time = appointment.appointment_time.slice(0, 10);
    return appointment;
  };

  return (
    <>
      <Card className="Cards1">
        <Card.Body className="cardBoxes1">
          <h3 className="virtualReminder">Virtual Appointment Reminder</h3>
          <div className="virtualReminderBody">
            Appointment Meeting: {firstAppointment.appointment_time} at {firstAppointment.hour}:00
          </div>
          <div className="virtualReminderBody">
            Patient Name: {firstAppointment.firstName} {firstAppointment.lastName}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default VirtualAppointmentReminder;
