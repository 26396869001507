import React, { useEffect, useState } from "react";
import { Table, Container, Row } from "react-bootstrap";
import "../../styles/app.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import DashBanner from "../../components/dash_banner";
import GenericSideBar from "../../components/sidebar/GenericSideBar";
import refreshPage from "../../components/refreshPage";

function AdminViewUsers() {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    axios.get("https://novid390.herokuapp.com/getUsers").then(response => setStats(response.data));
  }, []);

  const handleDelete = async value => {
    console.log(value);
    await axios
      .delete(`https://novid390.herokuapp.com/deleteUser?_id=${value}`)
      .then(refreshPage)
      .catch(err => console.log(err));
  };

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <Container fluid>
          <Row>
            <DashBanner PageName="List of Users"></DashBanner>
          </Row>
        </Container>

        <div>
          <Table hover>
            <thead>
              <tr>
                <th>Last Name</th>
                <th>First Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Role</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {stats.map((data, index) => (
                <tr key={index}>
                  <td> {data.lastname} </td>
                  <td> {data.firstname} </td>
                  <td> {data.phonenumber} </td>
                  <td> {data.email} </td>
                  <td> {data.Roles} </td>

                  <td>
                    <Link to={"/Admin-Modify"}>
                      <Button
                        style={{
                          borderRadius: 35,
                          backgroundColor: "#21b6ae",
                        }}
                        variant="contained"
                        size="small"
                        disableElevation>
                        Modify
                      </Button>
                    </Link>
                  </td>
                  <td className="Admin-Report-Actions">
                    <Button
                      onClick={() => handleDelete(data._id)}
                      style={{
                        borderRadius: 35,
                        backgroundColor: "#21b6ae",
                      }}
                      variant="contained"
                      size="small"
                      disableElevation>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default AdminViewUsers;
