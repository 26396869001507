import React from "react";
import SubmitButton from "./submit_button";
import logo from "../assets/novid_logo.png";
import { Link } from "react-router-dom";

class register extends React.Component {
  render() {
    return (
      // The name property of every field needs to be the same as the state so that the relation is established
      <div className="drkbg-container">
        <div className="auth-form-box">
          <form className="auth-form input">
            <img className={"image-logo"} src={logo} alt="logo" />
            <p style={{ color: "white" }}> I am a...</p>
            <Link to="/patientReg">
              <SubmitButton text="Patient" />
            </Link>
            <Link to="/employeeReg">
              <SubmitButton text="Employee" />
            </Link>
          </form>
        </div>
      </div>
    );
  }
}

export default register;
