import { Card, Form, Row, Col } from "react-bootstrap";
import * as ImIcons from "react-icons/im";

function PrimaryStats({ feel, temperature, weight, comments }) {
  return (
    <>
      <Card className="Cards">
        <Card.Header>
          <Card.Title as="h4" className="nameTitles">
            How do you feel?
          </Card.Title>
        </Card.Header>
        <Card.Body className="cardBoxes">
          <ImIcons.ImSmile color={feel === "smile" ? "red" : "black"} size={70} className="Emoti" />{" "}
          <ImIcons.ImNeutral color={feel === "neutral" ? "red" : "black"} size={70} className="Emoti" />{" "}
          <ImIcons.ImConfused color={feel === "confused" ? "red" : "black"} size={70} className="Emoti" />{" "}
          <ImIcons.ImSad color={feel === "sad" ? "red" : "black"} size={70} className="Emoti" />
        </Card.Body>
      </Card>
      <div>
        <Card>
          <Card.Header>
            <Card.Title as="h4" className="nameTitles">
              Temperature &amp; Weight
            </Card.Title>
          </Card.Header>
          <Card.Body className="cardBoxes">
            <Row>
              <Col>
                <h5>Temperature °C</h5>
                <h5>Weight (kg)</h5>
              </Col>
              <Col>
                <Form.Control placeholder={temperature} disabled />
                <Form.Control placeholder={weight} disabled />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
            <Card.Title as="h4" className="nameTitles ">
              Comments
            </Card.Title>
          </Card.Header>
          <Card.Body className="comments">
            <div>
              <p>{comments}</p>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
export default PrimaryStats;
