import React, { useEffect } from "react";
import { Table, Container, Row } from "react-bootstrap";
import * as BiIcons from "react-icons/bi";
import "../../styles/app.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import GenericSideBar from "../../components/sidebar/GenericSideBar";
import DashBanner from "../../components/dash_banner";

function ContactReport() {
  const [reportInfo, setReport] = useState([]);
  const [eventReport, setEventReport] = useState([]);

  useEffect(() => {
    axios.get("https://novid390.herokuapp.com/getIndividualContact").then(response => setReport(response.data));
    axios.get("https://novid390.herokuapp.com/getEventContact").then(response => setEventReport(response.data));
  }, []);

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <Container fluid>
          <Row>
            <DashBanner></DashBanner>
          </Row>
        </Container>

        <div>
          <div>
            <h4 className="nameTitles">Contact Tracing Summary</h4>
          </div>
          <br></br>
          <div>
            <h6 className="nameTitles">Individual Contact Tracing Summary</h6>
          </div>
          <Table hover>
            <thead>
              <tr>
                <th>Last Name</th>
                <th>First Name</th>
                <th>Email </th>
                <th>Phone Number </th>
                <th>Date of Contact</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {reportInfo.map((data, index) => (
                <tr key={index}>
                  <td> {data.lastName} </td>
                  <td> {data.firstName} </td>
                  <td> {data.email} </td>
                  <td> {data.phoneNumber} </td>
                  <td> {data.date.slice(0, 10)} </td>
                  <td>
                    <Link to={`/contact-report-history/${data._id}`}>
                      <BiIcons.BiSearchAlt />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <br></br>
          <div>
            <h6 className="nameTitles">Event/Store Contact Tracing Summary</h6>
          </div>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Location</th>
                <th>Date of Visit</th>
              </tr>
            </thead>
            <tbody>
              {eventReport.map((data, index) => (
                <tr key={index}>
                  <td> {data.eventName} </td>
                  <td> {data.location} </td>
                  <td> {data.date.slice(0, 10)} </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default ContactReport;
