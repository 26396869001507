import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import logo from "../../assets/novid_logo.png";
import { Sidebar_options_patients } from "./sidebar_options_patients";
import { Sidebar_options_admin } from "./sidebar_options_admin";
import { Sidebar_options_doctor } from "./sidebar_options_doctor";
import { Sidebar_options_health } from "./sidebar_options_health";
import { Sidebar_options_immigration } from "./sidebar_options_immigration";
import "../../styles/app.css";
import "../../styles/nav.css";
import { IconContext } from "react-icons";
import "react-bootstrap";
import Cookies from "js-cookie";
import { useHistory } from "react-router";

function GenericSideBar() {
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  const userRole = Cookies.get("role");
  var options;

  function assignOptions() {
    if (userRole === "Patient") {
      options = Sidebar_options_patients;
    } else if (userRole === "Doctor") {
      options = Sidebar_options_doctor;
    } else if (userRole === "Immigration Officer") {
      options = Sidebar_options_immigration;
    } else if (userRole === "Health Official") {
      options = Sidebar_options_health;
    } else if (userRole === "Admin") {
      options = Sidebar_options_admin;
    }
  }

  const showSidebar = () => setSidebar(!sidebar);

  const LogOut = async () => {
    Cookies.remove("role");
    Cookies.remove("userid");
    history.push("/");
  };

  assignOptions();

  return (
    <>
      <IconContext.Provider value={{ color: "#ffffff" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            <img className={"image-logo"} src={logo} alt="logo" />
            {options.map((item, index) => {
              return (
                <li key={index} className={item.className}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
            <li className="nav-text">
              <Link to="/login" onClick={LogOut}>
                <MdIcons.MdLogout />
                <span>Log Out</span>
              </Link>
            </li>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default GenericSideBar;
