import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import DashBanner from "../../components/dash_banner";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Cookies from "js-cookie";
import GenericSideBar from "../../components/sidebar/GenericSideBar";

function ScheduleAppointment() {
  const [reservedTimeSlots, setreservedTimeSlots] = useState([]);

  const [reserved, setReserved] = useState([]);

  const history = useHistory();
  const [timeSlots] = useState([9, 10, 11, 12, 13, 14, 15, 16, 17]);
  const { patient_id } = useParams();
  const [patientName, setPatientName] = useState("");

  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [time, setTime] = useState("");

  const doctorId = Cookies.get("userid");

  const submitAppointment = async () => {
    appointmentDate.setHours(time, 0, 0, 0);
    const appointmentDateFormated = appointmentDate.toLocaleDateString("en-US");

    axios.post("https://novid390.herokuapp.com/create_appointment", {
      doctor_id: doctorId,
      patient_id: patient_id,
      appointment_time: appointmentDate,
      hour: Number(time),
      reminder_Sent: false,
    });

    axios.post("https://novid390.herokuapp.com/notify_patients_about_appointment", {
      doctor_id: doctorId,
      patient_id: patient_id,
      appointment_time: appointmentDateFormated,
      //reminder_Sent: false,
    });

    history.push("/patient-report");
  };

  //Getting the Patient's Name
  useEffect(() => {
    axios.get("https://novid390.herokuapp.com/getPatients?_id=" + patient_id, {}).then(response => {
      setPatientName(response.data[0].firstname + " " + response.data[0].lastname);
    });
  }, [reservedTimeSlots, patient_id]);

  const updateTimeSlots = async value => {
    resetArray();
    await axios
      .get(
        `https://novid390.herokuapp.com/get_all_appointments_from_1_day?doctor_id=${doctorId}&appointment_time=${value}`
      )
      .then(response => setReserved(response.data))
      .then(reserved.map(data => setreservedTimeSlots(reservedTimeSlots => reservedTimeSlots.concat(data.hour))));
  };

  const resetArray = () => {
    setreservedTimeSlots([0]);
  };

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <DashBanner PageName="SCHEDULE AN APPOINTMENT" />
        <Card>
          <Card.Body className="cardBoxes">
            <Row>
              <Col lg={4}>
                <Card.Header>
                  <Card.Title className="nameTitles " as="h4">
                    Patient Name <br />
                  </Card.Title>
                  {patientName}
                </Card.Header>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Card.Title className="nameTitles " as="h4">
                  Select Date
                </Card.Title>
                <Calendar
                  className="calender"
                  onChange={(value, event) => {
                    setAppointmentDate(value);
                    updateTimeSlots(value);
                  }}
                  value={appointmentDate}
                />
              </Col>
              <Col lg={6}>
                <Card.Title className="nameTitles " as="h4">
                  Select Time
                </Card.Title>
                <FormControl fullWidth>
                  <InputLabel id="appointmentTime">Time</InputLabel>
                  <Select
                    labelId="setAppointmentTime"
                    id="appointmentTime"
                    value={time}
                    label="Time"
                    onChange={event => setTime(event.target.value)}>
                    {timeSlots.map((timeslot, index) => (
                      <MenuItem value={timeslot} {...(reservedTimeSlots.includes(timeslot) ? { disabled: true } : "")}>
                        {timeslot}:00
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Button
          form="individualContactForm"
          variant="flat"
          className="buttonColor submit_contact_form_button"
          onClick={submitAppointment}>
          Submit
        </Button>
      </div>
    </>
  );
}
export default ScheduleAppointment;
