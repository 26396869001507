import React from "react";
import * as FaIcons from "react-icons/fa";

export const Sidebar_options_admin = [
  {
    title: "View Users",
    path: "/view-users-admin",
    icon: <FaIcons.FaUsers />,
    className: "nav-text",
  },
  {
    title: "Assign Doctor / Patient",
    path: "/search-patient",
    icon: <FaIcons.FaUserCog />,
    className: "nav-text",
  },
  {
    title: "Create Users",
    path: "/create-user-admin",
    icon: <FaIcons.FaUserPlus />,
    className: "nav-text",
  },
];
