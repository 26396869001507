import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Container } from "react-bootstrap";
import "../../styles/messenger.css";
import Conversation from "../../components/conversations/conversation";
import Message from "../../components/message/message";
import { io } from "socket.io-client";
import DashBanner from "../../components/dash_banner";
import GenericSideBar from "../../components/sidebar/GenericSideBar";

function Messenger() {
  const [userid] = useState(Cookies.get("userid"));
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [writeMessage, setWriteMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const socket = useRef();
  const scrollRef = useRef();

  useEffect(() => {
    socket.current = io("https://novid.vercel.app");
    socket.current.on("getMessage", data => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);
  // if any changes to arrival messages, then we change update the message, first check if there is arrival message,
  useEffect(() => {
    arrivalMessage &&
      currentChat?.users.includes(arrivalMessage.sender) &&
      setMessages(prev => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    socket.current.emit("addUser", userid);
    socket.current.on("getUsers", users => {
      console.log(users);
    });
  }, [userid]);

  //get the conversations of a particular user
  useEffect(() => {
    const getConversations = async () => {
      try {
        const res = await axios.get("https://novid390.herokuapp.com/conversations/" + userid);
        //console.log(res)
        setConversations(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
  }, [userid]);

  //get the messages of the particular conversation
  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await axios.get("https://novid390.herokuapp.com/messages/" + currentChat?._id);
        setMessages(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMessages();
  }, [currentChat]);

  // write message and submit to db on send button click
  const handleSubmit = async e => {
    e.preventDefault();
    const message = {
      conversationId: currentChat._id,
      sender: userid,
      text: writeMessage,
    };

    const receiverId = currentChat.users.find(user => user !== userid);

    socket.current.emit("sendMessage", {
      senderId: userid,
      receiverId,
      text: writeMessage,
    });

    try {
      const res = await axios.post("https://novid390.herokuapp.com/messages", message);
      setMessages([...messages, res.data]);
      setWriteMessage("");
    } catch (err) {
      console.log(err);
    }
  };

  // handle scrolling on new message
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <Container fluid>
          <DashBanner PageName="Messenger" />

          <div className="messenger">
            <div className="chatMenu">
              <div className="chatMenuWrapper">
                <div className="ContactList">
                  <p>Contact List</p>
                </div>
                {conversations.map(c => (
                  <div onClickCapture={() => setCurrentChat(c)}>
                    <Conversation conversation={c} currentUser={userid} />
                  </div>
                ))}
              </div>
            </div>
            <div className="chatBox">
              <div className="chatBoxWrapper">
                {currentChat ? (
                  <>
                    <div className="chatBoxTop">
                      {messages.map(mes => (
                        <div ref={scrollRef}>
                          <Message message={mes} own={mes.sender === userid} />
                        </div>
                      ))}
                    </div>
                    <div className="chatBoxBottom">
                      <textarea
                        className="chatMessageInput"
                        placeholder="Message..."
                        onChange={e => setWriteMessage(e.target.value)}
                        value={writeMessage}></textarea>
                      <button className="chatSubmitButton" onClick={handleSubmit}>
                        Send
                      </button>
                    </div>
                  </>
                ) : (
                  <span className="defaultConversationText"> Click on a user to start a conversation. </span>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Messenger;
