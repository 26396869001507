import React, { useEffect, useState } from "react";
import "../../styles/app.css";
import { useParams } from "react-router-dom";
// react-bootstrap components
import { Card, Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import DashBanner from "../../components/dash_banner";
import GenericSideBar from "../../components/sidebar/GenericSideBar";
import EmotionForm from "../../components/emotion_form";

function UpdateStatus() {
  const [statusId] = useState(useParams());
  const [status, setStatus] = useState("");
  const [feels, setFeel] = useState(status.feels);
  const [temperature, setTemperature] = useState(status.temperature);
  const [weight, setWeight] = useState(status.weight);
  const [comments, setComments] = useState(status.comments);
  const [symptoms, setSymptoms] = useState([]);
  const [showSuccess, setSuccess] = useState(false);

  const addSymptom = symptom => {
    setSymptoms(Array.from(new Set([...symptoms, symptom])));
  };

  const changeFeels = feel => {
    console.log(feel);
    return setFeel(feel);
  };

  useEffect(() => {
    axios
      .get("https://novid390.herokuapp.com/getStatus?_id=" + statusId._id)
      .then(response => response.data)
      .then(response => setStatus(response[0]))
      .catch(err => console.log(err));
  }, [statusId._id]);

  const submitForm = () => {
    axios
      .put("https://novid390.herokuapp.com/putUpdateStatus?_id=" + statusId._id, {
        feel: feels,
        weight: weight,
        temperature: temperature,
        comments: comments,
        symptoms: symptoms,
      })
      .then(response => console.log(response));
    setSuccess(true);
  };

  if (!status) {
    return null;
  }

  return (
    <>
      <GenericSideBar />

      <div className="page-container">
        <Container fluid>
          <Row>
            <DashBanner></DashBanner>
          </Row>
          <Row>
            <Col md={6}>
              <div>
                <h4 className="nameTitles">Update Status</h4>
              </div>
            </Col>
            <Col md={4}>
              <label className="inline TestedPostive">Tested Positive?</label>
              <Row>
                <Col md={3}>
                  <Form.Check type="radio" label="Yes" name="covid" value="Yes" />
                </Col>
                <Col md={3}>
                  <Form.Check type="radio" label="No" name="covid" value="No" />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <EmotionForm feels={feels} changeFeels={changeFeels} />
              <div>
                <Card>
                  <Card.Header>
                    <Card.Title as="h4" className="nameTitles">
                      Temperature &amp; Weight
                    </Card.Title>
                  </Card.Header>

                  <Card.Body className="cardBoxes">
                    <Row>
                      <Col>
                        <h5>Temperature °C</h5>
                        <h5>Weight (kg)</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          defaultValue={status.temperature}
                          onChange={e => {
                            setTemperature(e.target.value);
                          }}
                        />
                        <Form.Control
                          defaultValue={status.weight}
                          onChange={e => {
                            setWeight(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header>
                    <Card.Title as="h4" className="nameTitles ">
                      Comments
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="comments">
                    <Form.Control
                      onChange={e => setComments(e.target.value)}
                      as="textarea"
                      rows={3}
                      type="text"
                      defaultValue={status.comments}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>

            <Col md="4">
              <Card>
                <Card.Header>
                  <Card.Title as="h4" className="nameTitles">
                    Applicable Symptoms
                  </Card.Title>
                </Card.Header>
                <Card.Body className="cardBoxes">
                  <div>
                    <Row>
                      <fieldset>
                        <Form.Group>
                          <Form.Label className="SymptomTitles"> Most Common Symptoms </Form.Label>
                          <Form.Check
                            label="Fever"
                            type="radio"
                            value="fever"
                            onChange={e => {
                              addSymptom(e.target.value);
                            }}
                          />
                          <Form.Check
                            label="Cough"
                            type="radio"
                            value="cough"
                            onChange={e => {
                              addSymptom(e.target.value);
                            }}
                          />
                          <Form.Check
                            label="Tiredness"
                            type="radio"
                            value="tiredness"
                            onChange={e => {
                              addSymptom(e.target.value);
                            }}
                          />
                          <Form.Check
                            label="Loss of taste or smell"
                            type="radio"
                            value="loss of taste or smell"
                            onChange={e => {
                              addSymptom(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </fieldset>
                    </Row>
                    <Row>
                      <fieldset>
                        <Form.Group>
                          <Form.Label className="SymptomTitles"> Less Common Symptoms</Form.Label>
                          <Form.Check
                            label="Sore throat"
                            type="radio"
                            value="sore throat"
                            onChange={e => {
                              addSymptom(e.target.value);
                            }}
                          />
                          <Form.Check
                            label="Headache"
                            type="radio"
                            value="headache"
                            onChange={e => {
                              addSymptom(e.target.value);
                            }}
                          />
                          <Form.Check
                            label="Aches and pains"
                            type="radio"
                            value="aches and pains"
                            onChange={e => {
                              addSymptom(e.target.value);
                            }}
                          />
                          <Form.Check
                            label="Diarrhea"
                            type="radio"
                            value="diarrhea"
                            onChange={e => {
                              addSymptom(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </fieldset>
                    </Row>
                    <Row>
                      <fieldset>
                        <Form.Group>
                          <Form.Label className="SymptomTitles"> Serious Symptoms </Form.Label>

                          <Form.Check
                            label="Difficulty breathing"
                            type="radio"
                            value="difficulty breathing"
                            onChange={e => {
                              addSymptom(e.target.value);
                            }}
                          />
                          <Form.Check
                            label="Shortness of breath"
                            type="radio"
                            value="shortness of breath"
                            onChange={e => {
                              addSymptom(e.target.value);
                            }}
                          />
                          <Form.Check
                            label="Chest pain"
                            type="radio"
                            value="chest pain"
                            onChange={e => {
                              addSymptom(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </fieldset>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br></br>
          <Button
            onClick={() => submitForm()}
            type="submit"
            form="status"
            variant="flat"
            className="buttonColor submit_status">
            Update Status
          </Button>
          {showSuccess ? <Form.Label className="success">Status updated.</Form.Label> : null}
        </Container>
      </div>
    </>
  );
}

export default UpdateStatus;
