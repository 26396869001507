import React, { useState, useEffect } from "react";
// import * as BiIcons from "react-icons/bi";
// import * as HiIcons from "react-icons/hi";
import "./../styles/app.css";
// react-bootstrap components
import { Card, Container, Row, Col } from "react-bootstrap";
import "../styles/user_banner.css";
import FilterByDD from "../components/dashFilters/filterByDD.js";
import DashBanner from "../components/dash_banner";
import GenericSideBar from "../components/sidebar/GenericSideBar";

import axios from "axios";
import LineChart from "../components/charts/lineChart";
import FilterByPatient from "../components/dashFilters/filterByPatient";
import DisplayPatients from "../components/dashboard/displayPatients";
import Cookies from "js-cookie";
import VirtualAppointmentReminder from "../components/dashboard/virtualAppointmentReminder";
import FlaggedPatients from "../components/dashboard/flaggedPatients";

function Dashboard() {
  const userid = Cookies.get("userid");
  const [selected, setSelected] = useState("Weight");
  const [selectedUser, setSelectedUser] = useState("");
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    let tempData = [];
    let weightData = [];
    let dateData = [];

    // get all status from patient with specified id
    const getStatusData = async () => {
      try {
        const res = await axios.get(
          "https://novid390.herokuapp.com/getStatusById?patientID=" + selectedUser?.split(" ")[0],
          {}
        );

        // get temperatures, mood, weight
        for (const dataObj of res.data) {
          tempData.push(parseInt(dataObj.temperature));
          weightData.push(parseInt(dataObj.weight));
          dateData.push(dataObj.date.split("T", 1));
        }

        // set chart data to selected filter
        if (selected === "Temperature") {
          setChartData({
            labels: dateData.reverse(),
            datasets: [
              {
                label: "Temperature change",
                data: tempData.reverse(),
                backgroundColor: ["#76d0c9"],
              },
            ],
          });
        } else if (selected === "Weight") {
          setChartData({
            labels: dateData.reverse(),
            datasets: [
              {
                label: "Weight change",
                data: weightData.reverse(),
                backgroundColor: ["#11847c"],
              },
            ],
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    getStatusData();
  }, [selectedUser, selected]);

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <Container fluid>
          <Row>
            <DashBanner PageName="Dashboard" />
          </Row>
          <VirtualAppointmentReminder userid={userid} />
          <Row>
            <Col md="4">
              <Card>
                <Card.Header>
                  <Card.Title as="h4" className="nameTitles">
                    Your Patients
                  </Card.Title>
                </Card.Header>
                <Card.Body className="cardPatients">
                  <DisplayPatients />
                </Card.Body>
              </Card>
            </Col>

            <Col md="6">
              <div>
                <Card>
                  <Card.Header>
                    <Card.Title as="h4" className="nameTitles">
                      <div className="oneLine">
                        {selected} Trends
                        <span className="filterSize">
                          <FilterByDD selected={selected} setSelected={setSelected} />
                          <span className="filterSize">
                            <FilterByPatient selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
                          </span>
                        </span>
                      </div>
                    </Card.Title>
                  </Card.Header>
                  {selected && selectedUser ? (
                    <div>
                      <LineChart chartData={chartData} />
                    </div>
                  ) : (
                    <span className="defaultGraphText"> Click on a filter and user to view a chart. </span>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <FlaggedPatients userid={userid} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Dashboard;
