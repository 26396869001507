import React, { useState, useEffect } from "react";
import "../../styles/displayPatients.css";
import Cookies from "js-cookie";
import axios from "axios";
import { BsFillPersonFill } from "react-icons/bs";

function DisplayPatients() {
  const [patients, setPatients] = useState([]);
  let userOptions = [];
  //used to get and display doctors patients
  useEffect(() => {
    axios
      .get("https://novid390.herokuapp.com/getPatients?assignedDoctor=" + Cookies.get("userid"))
      .then(response => {
        setPatients(response.data);
      })
      .catch(err => console.log(err));
  }, []);

  // assign an array the patients name and ID for displaying and setting selectedUser
  for (const patient of patients) {
    userOptions.push(patient.firstname + " " + patient.lastname);
  }

  return (
    <>
      {
        <div className="patient-content">
          {userOptions.map((people, index) => (
            <div className="list-item" key={index}>
              <BsFillPersonFill />
              {"" + people.split(" ")[0] + " " + people.split(" ")[1]}
            </div>
          ))}
        </div>
      }
    </>
  );
}

export default DisplayPatients;
