import React from "react";

class GenericButton extends React.Component {
  render() {
    return (
      <div className={this.props.classes}>
        <button className="btn" type={this.props.type} disabled={this.props.disabled} onClick={this.props.onClick}>
          {this.props.text}
        </button>
      </div>
    );
  }
}

export default GenericButton;
