import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import DashBanner from "../../components/dash_banner";
import GenericSideBar from "../../components/sidebar/GenericSideBar";

function PatientsAppoinments() {
  const [Patientid] = useState(Cookies.get("userid"));
  const [patientAppointments, setpatientAppointments] = useState([]);
  useEffect(() => {
    axios
      .get("https://novid390.herokuapp.com/get_appointments?patient_id=" + Patientid)
      .then(response => setpatientAppointments(response.data));
  }, [Patientid]);

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <DashBanner PageName="Appointments" />
        <Table bordered hover>
          <thead>
            <tr>
              <th>Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {patientAppointments.map(({ appointment_time }, index) => (
              <tr key={index}>
                <td>{appointment_time}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default PatientsAppoinments;
