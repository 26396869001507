import { Card, Form, Row } from "react-bootstrap";

function Symptoms({ allSymptoms }) {
  return (
    <Card>
      <Card.Header>
        <Card.Title as="h4" className="nameTitles">
          Applicable Symptoms
        </Card.Title>
      </Card.Header>

      <Card.Body className="cardBoxes">
        <div>
          <Row>
            <Form.Group>
              <Form.Label className="SymptomTitles"> Most Common Symptoms </Form.Label>
              <Form.Check
                label="Fever"
                type="radio"
                {...(allSymptoms.includes("fever") ? { checked: true } : "")}
                disabled
              />
              <Form.Check
                label="Cough"
                type="radio"
                {...(allSymptoms.includes("cough") ? { checked: true } : "")}
                disabled
              />
              <Form.Check
                label="Tiredness"
                type="radio"
                {...(allSymptoms.includes("tiredness") ? { checked: true } : "")}
                disabled
              />
              <Form.Check
                label="Loss of taste or smell"
                type="radio"
                {...(allSymptoms.includes("loss of taste or smell") ? { checked: true } : "")}
                disabled
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group>
              <Form.Label className="SymptomTitles"> Less Common Symptoms</Form.Label>
              <Form.Check
                label="Sore throat"
                type="radio"
                {...(allSymptoms.includes("sore throat") ? { checked: true } : "")}
                disabled
              />
              <Form.Check
                label="Headache"
                type="radio"
                {...(allSymptoms.includes("headache") ? { checked: true } : "")}
                disabled
              />
              <Form.Check
                label="Aches and pains"
                type="radio"
                {...(allSymptoms.includes("aches and pains") ? { checked: true } : "")}
                disabled
              />
              <Form.Check
                label="Diarrhea"
                type="radio"
                {...(allSymptoms.includes("diarrhea") ? { checked: true } : "")}
                disabled
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group>
              <Form.Label className="SymptomTitles"> Serious Symptoms </Form.Label>

              <Form.Check
                label="Difficulty breathing"
                type="radio"
                {...(allSymptoms.includes("difficulty breathing") ? { checked: true } : "")}
                disabled
              />

              <Form.Check
                label="Shortness of breath"
                type="radio"
                {...(allSymptoms.includes("shortness of breath") ? { checked: true } : "")}
                disabled
              />
              <Form.Check
                label="Chest pain"
                type="radio"
                {...(allSymptoms.includes("chest pain") ? { checked: true } : "")}
                disabled
              />
            </Form.Group>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}
export default Symptoms;
