import React, { useState, useEffect } from "react";
import "../../styles/filterByDD.css";
import { BsFillPencilFill, BsCaretDownFill } from "react-icons/bs";

import Cookies from "js-cookie";
import axios from "axios";

function FilterByPatient({ selectedUser, setSelectedUser }) {
  const [isActive, setIsActive] = useState(false);

  const [patients, setPatients] = useState([]);
  let userOptions = [];

  //used to get and display doctors patients
  useEffect(() => {
    axios
      .get("https://novid390.herokuapp.com/getPatients?assignedDoctor=" + Cookies.get("userid"))
      .then(response => {
        setPatients(response.data);
      })
      .catch(err => console.log(err));
  }, []);

  // assign an array the patients name and ID for displaying and setting selectedUser
  for (const patient of patients) {
    userOptions.push(patient._id + " " + patient.firstname + " " + patient.lastname);
  }

  return (
    <>
      <div className="page-container">
        <div className="dropdown">
          <div className="dropdown-btn" onClick={() => setIsActive(!isActive)}>
            <BsFillPencilFill />{" "}
            {!selectedUser ? " Select User" : "User: " + selectedUser.split(" ")[1] + " " + selectedUser.split(" ")[2]}{" "}
            <BsCaretDownFill />
          </div>
          {isActive && (
            <div className="dropdown-content">
              {userOptions.map(option => (
                <div
                  onClick={() => {
                    setSelectedUser(option);
                    setIsActive(false);
                  }}
                  className="dropdown-item">
                  {option.split(" ")[1] + " " + option.split(" ")[2]}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FilterByPatient;
