import React, { useState } from "react";
import "../../styles/filterByDD.css";
import { BsFillPencilFill, BsCaretDownFill } from "react-icons/bs";

function FilterByDD({ selected, setSelected }) {
  const [isActive, setIsActive] = useState(false);
  const options = ["Weight", "Temperature"];

  return (
    <>
      <div className="page-container">
        <div className="dropdown">
          <div className="dropdown-btn" onClick={() => setIsActive(!isActive)}>
            <BsFillPencilFill /> {!selected ? " Filter by" : "Filtered by: " + selected} <BsCaretDownFill />
          </div>
          {isActive && (
            <div className="dropdown-content">
              {options.map(option => (
                <div
                  onClick={() => {
                    setSelected(option);
                    setIsActive(false);
                  }}
                  className="dropdown-item">
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FilterByDD;
