import React from "react";
import { Form, Col } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";

function RAMQ(props) {
  return (
    <Form.Group as={Col} controlId="">
      <Form.Label>RAMQ Number</Form.Label>
      <Form.Control
        type="text"
        placeholder=""
        {...props.register("RAMQ", {
          pattern: {
            value: /^[A-Za-z]{4}\d{8}$/,
            message: "Invalid RAMQ number",
          },
          required: props.required,
        })}
      />
      <ErrorMessage
        errors={props.errors}
        name="RAMQ"
        render={({ message }) => <div style={{ color: "red" }}>{message}</div>}
      />
    </Form.Group>
  );
}
export default RAMQ;
