import React, { useState } from "react";
import "../../styles/app.css";
import DashBanner from "../../components/dash_banner";
// react-bootstrap components
import { Card, Container, Row, Col, Form, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import EmotionForm from "../../components/emotion_form";
import GenericSideBar from "../../components/sidebar/GenericSideBar";

function AddStatus() {
  const [feels, setFeel] = useState("");
  const [temperature, setTemperature] = useState("");
  const [weight, setWeight] = useState(0);
  const [comments, setComments] = useState("");
  const [symptoms, setSymptoms] = useState([]);
  const [patientID] = useState(Cookies.get("userid"));

  const [showSuccess, setSuccess] = useState(false);

  const addSymptom = symptom => {
    setSymptoms(Array.from(new Set([...symptoms, symptom])));
  };

  const changeFeels = feel => {
    console.log(feel);
    return setFeel(feel);
  };

  const submitForm = () => {
    axios
      .post("https://novid390.herokuapp.com/createStatus/", {
        patientID: patientID,
        feel: feels,
        weight: weight,
        temperature: temperature,
        comments: comments,
        symptoms: symptoms,
      })
      .then(response => console.log(response));
    setSuccess(true);
  };

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <Container fluid>
          <Row>
            <DashBanner PageName="Add Status"></DashBanner>
          </Row>

          <Row>
            <Col md="6">
              <EmotionForm feels={feels} changeFeels={changeFeels} />

              <div>
                <Card>
                  <Card.Header>
                    <Card.Title as="h4" className="nameTitles ">
                      Temperature &amp; Weight
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="cardBoxes">
                    <div>
                      <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                          <Form.Label column sm={3}>
                            Temperature
                          </Form.Label>
                          <Col sm={6}>
                            <Form.Control
                              type="text"
                              placeholder="(°C)"
                              onChange={e => setTemperature(e.target.value)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                          <Form.Label column sm={3}>
                            Weight
                          </Form.Label>
                          <Col sm={6}>
                            <Form.Control type="text" placeholder="(kg)" onChange={e => setWeight(e.target.value)} />
                          </Col>
                        </Form.Group>
                      </Form>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div>
                <Card>
                  <Card.Header>
                    <Card.Title as="h4" className="nameTitles ">
                      Comments
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="cardBoxes">
                    <div>
                      <Col sm={12}>
                        <Form.Control
                          onChange={e => setComments(e.target.value)}
                          as="textarea"
                          rows={3}
                          type="text"
                          placeholder=""
                        />
                      </Col>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>

            <Col md="4">
              <Card>
                <Card.Header>
                  <Card.Title as="h4" className="nameTitles">
                    Applicable Symptoms
                  </Card.Title>
                </Card.Header>
                <Card.Body className="cardBoxes">
                  <Row>
                    <Form.Group>
                      <Form.Label className="SymptomTitles"> Most Common Symptoms </Form.Label>
                      <Form.Check label="Fever" type="radio" value="fever" onClick={e => addSymptom(e.target.value)} />
                      <Form.Check label="Cough" type="radio" value="cough" onClick={e => addSymptom(e.target.value)} />
                      <Form.Check
                        label="Tiredness"
                        type="radio"
                        value="tiredness"
                        onClick={e => addSymptom(e.target.value)}
                      />
                      <Form.Check
                        label="Loss of taste or smell"
                        type="radio"
                        value="loss of taste or smell"
                        onClick={e => addSymptom(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group>
                      <Form.Label className="SymptomTitles"> Less Common Symptoms</Form.Label>
                      <Form.Check
                        label="Sore throat"
                        type="radio"
                        value="sore throat"
                        onClick={e => addSymptom(e.target.value)}
                      />
                      <Form.Check
                        label="Headache"
                        type="radio"
                        value="headache"
                        onClick={e => addSymptom(e.target.value)}
                      />
                      <Form.Check
                        label="Aches and pains"
                        type="radio"
                        value="aches and pains"
                        onClick={e => addSymptom(e.target.value)}
                      />
                      <Form.Check
                        label="Diarrhea"
                        type="radio"
                        value="diarreha"
                        onClick={e => addSymptom(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group>
                      <Form.Label className="SymptomTitles"> Serious Symptoms </Form.Label>
                      <Form.Check
                        label="Difficulty breathing"
                        type="radio"
                        value="difficulty breathing"
                        onClick={e => addSymptom(e.target.value)}
                      />
                      <Form.Check
                        label="Shortness of breath"
                        type="radio"
                        value="shortness of breath"
                        onClick={e => addSymptom(e.target.value)}
                      />
                      <Form.Check
                        label="Chest pain"
                        type="radio"
                        value="chest pain"
                        onClick={e => addSymptom(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br></br>
          <Button
            onClick={() => submitForm()}
            type="submit"
            form="status"
            variant="flat"
            className="buttonColor submit_status">
            Submit
          </Button>
          {showSuccess ? <Form.Label className="success">Status submitted.</Form.Label> : null}
        </Container>
      </div>
    </>
  );
}

export default AddStatus;
