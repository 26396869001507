import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as BiIcons from "react-icons/bi";
import axios from "axios";
import { useEffect, useState } from "react";

function FlaggedPatients({ userid }) {
  const [flaggedPatients, setFlaggedPatients] = useState([]);

  useEffect(() => {
    getFlaggedPatients();
  });

  const getFlaggedPatients = () => {
    axios
      .get(`https://novid390.herokuapp.com/get_flagged_patients?doctorid=${userid}`)
      .then(response => setFlaggedPatients(response.data));
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title as="h4" className="nameTitles ">
            Your Flagged Patients
          </Card.Title>
        </Card.Header>
        <Card.Body className="comments">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>RAMQ</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {flaggedPatients.map((patient, index) => (
                <tr key={index}>
                  <th>{index + 1}</th>
                  <th>{patient.firstname}</th>
                  <th>{patient.lastname}</th>
                  <th>{patient.RAMQ}</th>
                  <th>
                    <Link to={`/patient-status-history/${patient._id}`}>
                      <BiIcons.BiSearchAlt size={21} /> Reports
                    </Link>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}
export default FlaggedPatients;
