import React from "react";
import { Form } from "react-bootstrap";

function MedicalID(props) {
  return (
    <Form.Group controlId="">
      <Form.Label>Medical License ID (Optional)</Form.Label>
      <Form.Control placeholder="" type="text" {...props.register("DoctorId")} />
    </Form.Group>
  );
}
export default MedicalID;
