import React, { useEffect, useState } from "react";

import "../../styles/app.css";
import { useParams } from "react-router-dom";
// react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import DashBanner from "../../components/dash_banner";
import Symptoms from "../../components/status_page_components/symptoms";
import PrimaryStats from "../../components/status_page_components/prime_stats";
import GenericSideBar from "../../components/sidebar/GenericSideBar";
import TestedPostive from "../../components/status_page_components/tested_positIve";

function StatusOverview() {
  const [statusId] = useState(useParams());
  const [status, setStatus] = useState("");
  const [covidIdentifier, setCovidIdentifier] = useState(Boolean);

  useEffect(() => {
    axios
      .get("https://novid390.herokuapp.com/getStatus?_id=" + statusId._id)
      .then(response => response.data)
      .then(response => setStatus(response[0]))
      .catch(err => console.log(err));

    axios
      .post("https://novid390.herokuapp.com/getUserById/", {
        userid: status.patientID,
      })
      .then(response => response.data)
      .then(data => setCovidIdentifier(data.testedPositive))
      .catch(err => console.log(err));

    console.log(covidIdentifier);
  }, [statusId._id, status.patientID, covidIdentifier]);

  if (!status && !covidIdentifier) {
    return null;
  }

  return (
    <>
      <GenericSideBar />

      <div className="page-container">
        <Container fluid>
          <Row>
            <DashBanner></DashBanner>
          </Row>
          <Row>
            <Col md={6}>
              <div>
                <h4 className="nameTitles">Status Overview</h4>
              </div>
            </Col>
            <Col md={4}>
              <TestedPostive covidIdentifier={covidIdentifier} />
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <PrimaryStats
                temperature={status.temperature}
                weight={status.weight}
                feel={status.feel}
                comments={status.comments}
              />
            </Col>

            <Col md="4">
              <Symptoms allSymptoms={status.symptoms} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default StatusOverview;
