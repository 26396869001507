import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import DashBanner from "../../components/dash_banner";
import Cookies from "js-cookie";
import GenericSideBar from "../../components/sidebar/GenericSideBar";

function Appointments() {
  const doctorid = Cookies.get("userid");
  const [allAppointmentsByDoctor, setallAppointmentsByDoctor] = useState([]);

  useEffect(() => {
    getAllAppointments();
  });

  const getAllAppointments = async () => {
    //Get the list of all Appointments
    let listOfAppointments = await getAppointments();

    //Add the first names and last names to each appointment object so we can map it to the table
    let updatedAppointments = await listOfAppointments.map(async appointment => addNamesToAppointment(appointment));

    Promise.all(updatedAppointments).then(results => {
      setallAppointmentsByDoctor(results);
    });
  };

  const addNamesToAppointment = async appointment => {
    let user = await fetch("https://novid390.herokuapp.com/getPatients?_id=" + appointment.patient_id);
    let jsondUsers = await user.json();
    appointment.firstName = jsondUsers[0].firstname;
    appointment.lastName = jsondUsers[0].lastname;
    return appointment;
  };

  const getAppointments = async () => {
    const response = await fetch("https://novid390.herokuapp.com/get_appointments?doctor_id=" + doctorid);
    var listOfAppointments = await response.json();
    return listOfAppointments;
  };

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <DashBanner PageName="Appointments" />
        <Table bordered hover>
          <thead>
            <tr>
              <th datafield="userid">Last Name</th>
              <th>First Name</th>
              <th>Date &amp; Time</th>
            </tr>
          </thead>
          <tbody>
            {allAppointmentsByDoctor.map(({ lastName, firstName, appointment_time }, index) => (
              <tr key={index}>
                <td>{lastName}</td>
                <td>{firstName}</td>
                <td>{appointment_time.slice(0, 10)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}
export default Appointments;
