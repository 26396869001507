import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import "../styles/user_banner.css";
import duser from "../assets/default-avatar.jpg";

function DashBanner(props) {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [userid] = useState(Cookies.get("userid"));

  useEffect(() => {
    axios
      .post("https://novid390.herokuapp.com/getUserById", {
        userid: userid,
      })
      .then(response => {
        setName(response.data.firstname + " " + response.data.lastname);
        setRole(response.data.Roles);
      })
      .catch(err => console.log(err));
  }, [userid, role]);

  return (
    <Container fluid className="Banner">
      <Row>
        <Col>
          <img className="avatarPicture1" src={duser} alt="doc_lina" />
        </Col>
        <Col>
          <Row className="UserName">
            <h4>{name}</h4>
          </Row>
          <hr />
          <Row>
            <h4>{role}</h4>
          </Row>
        </Col>
        <Col xs={8} className="EMPTYSPACE"></Col>
      </Row>
      <Row>
        {/* <div className="sameLine"><h1 className="userBannerName">Welcome Back {name}</h1>
          <BsFillBellFill className="sizeChange" /><BsMailbox2 className="sizeChange" />
          </div> */}
      </Row>
      <hr />
      <div>
        <h4 className="nameTitles">{props.PageName}</h4>
      </div>
    </Container>
  );
}
export default DashBanner;
