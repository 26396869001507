import React from "react";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";

export const Sidebar_options_doctor = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <FaIcons.FaHome />,
    className: "nav-text",
  },
  {
    title: "Appointments",
    path: "/appointments",
    icon: <FaIcons.FaCalendarAlt />,
    className: "nav-text",
  },
  {
    title: "Patient Report",
    path: "/patient-report",
    icon: <BiIcons.BiBook />,
    className: "nav-text",
  },
  {
    title: "Contact Patients",
    path: "/messenger",
    icon: <BsIcons.BsFillChatFill />,
    className: "nav-text",
  },
];
