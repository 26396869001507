import React from "react";
import * as BiIcons from "react-icons/bi";

export const Sidebar_options_immigration = [
  {
    title: "Search Patient",
    path: "/search-patient",
    icon: <BiIcons.BiSearchAlt />,
    className: "nav-text",
  },
];
