import { Link } from "react-router-dom";
import FlagButton from "../../components/generic_button";
import { BsFillFlagFill, BsFlag } from "react-icons/bs";
import axios from "axios";
function FlaggingPatient({ flag, patientID, link }) {
  const updateFlagOnPatient = () => {
    axios.put(`https://novid390.herokuapp.com/update_flag?_id=${patientID}`);
  };
  return (
    <div className="thebuttons">
      <Link to={link}>
        <FlagButton
          className="FlagButton"
          type="button"
          text={flag ? <BsFillFlagFill color="red" size={30} /> : <BsFlag size={30} />}
          onClick={() => updateFlagOnPatient()}
        />
      </Link>
    </div>
  );
}

export default FlaggingPatient;
