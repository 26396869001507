import React, { useState } from "react";
import * as ImIcons from "react-icons/im";
import "../styles/app.css";
import { Card } from "react-bootstrap";

function EmotionForm({ feels, changeFeels }) {
  const [toggleSmile, setToggleSmile] = useState(false);
  const [toggleNeutral, setToggleNeutral] = useState(false);
  const [toggleConfused, setToggleConfused] = useState(false);
  const [toggleSad, setToggleSad] = useState(false);

  return (
    <Card className="Cards">
      <Card.Header>
        <Card.Title as="h4" className="nameTitles">
          How do you feel?
        </Card.Title>
      </Card.Header>
      <Card.Body className="cardBoxes">
        <ImIcons.ImSmile
          onClick={() => [changeFeels("smile"), setToggleSmile(!toggleSmile)]}
          color={toggleSmile ? "red" : "black"}
          size={70}
          className="Emoti"
        />{" "}
        <ImIcons.ImNeutral
          onClick={() => [changeFeels("neutral"), setToggleNeutral(!toggleNeutral)]}
          color={toggleNeutral ? "red" : "black"}
          size={70}
          className="Emoti"
        />{" "}
        <ImIcons.ImConfused
          onClick={() => [changeFeels("confused"), setToggleConfused(!toggleConfused)]}
          color={toggleConfused ? "red" : "black"}
          size={70}
          className="Emoti"
        />{" "}
        <ImIcons.ImSad
          onClick={() => [changeFeels("sad"), setToggleSad(!toggleSad)]}
          color={toggleSad ? "red" : "black"}
          size={70}
          className="Emoti"
        />
      </Card.Body>
    </Card>
  );
}
export default EmotionForm;
