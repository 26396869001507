import React from "react";
import { Col, Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";

function DateOfBirth(props) {
  return (
    <Form.Group as={Col} controlId="">
      <Form.Label>Date of Birth</Form.Label>
      <Form.Control
        type="date"
        placeholder="MM-DD-YYYY"
        {...props.register("DateofBirth", {
          required: { value: true, message: "This field is required" },
        })}
      />
      <ErrorMessage
        errors={props.errors}
        name="DateofBirth"
        render={({ message }) => <div style={{ color: "red" }}>{message}</div>}
      />
    </Form.Group>
  );
}
export default DateOfBirth;
