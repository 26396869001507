import { Col, Row } from "react-bootstrap";
import DashBanner from "../../components/dash_banner";

import PrimaryStats from "../../components/status_page_components/prime_stats";
import Symptoms from "../../components/status_page_components/symptoms";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
import GenericSideBar from "../../components/sidebar/GenericSideBar";
import TestedPostive from "../../components/status_page_components/tested_positIve";
import FlaggingPatient from "../../components/status_page_components/flag";

function PatientMostRecentReport() {
  const { _id } = useParams();
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [covidIdentifier, setcovidIdentifier] = useState("");
  const [flag, setFlag] = useState(false);
  //Get the Patient's name
  useEffect(() => {
    axios
      .get(`https://novid390.herokuapp.com/getUsers?_id=${_id}`)
      .then(response => [
        setName(`${response.data[0].firstname} ${response.data[0].lastname}`),
        setcovidIdentifier(response.data[0].testedPositive),
        setFlag(response.data[0].flag),
      ]);

    axios
      .get(`https://novid390.herokuapp.com/get_latest_status?patientID=${_id}`)
      .then(response => setStatus(response.data));
  }, [status, _id]);

  if (!status && name.firstname == null) {
    return null;
  }

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <Row>
          <DashBanner />
        </Row>
        <Row>
          <Col md={6}>
            <div>
              <h4 className="nameTitles">{name}: Overview</h4>
            </div>
          </Col>
          <Col md={4}>
            <TestedPostive covidIdentifier={covidIdentifier} />
          </Col>

          <Col md={2}>
            <FlaggingPatient flag={flag} patientID={status.patientID} link="/search-patient" />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <PrimaryStats
              temperature={status.temperature}
              weight={status.weight}
              feel={status.feel}
              comments={status.comments}
            />
          </Col>

          <Col md="4">
            <Symptoms allSymptoms={status.symptoms} />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PatientMostRecentReport;
