import React from "react";
import SubmitButton from "./submit_button";
import logo from "../assets/novid_logo.png";
import Cookies from "universal-cookie";
import Cookiesjs from "js-cookie";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "../styles/app.css";

const userRole = Object.freeze({
  Patient: "Patient",
  HealthOfficial: "Health Official",
  Doctor: "Doctor",
  Admin: "Admin",
  ImmigrationOfficer: "Immigration Officer",
});
class login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "" };
  }

  onChange = e => {
    // If using states to handle, this line will index the state variable with the same name as the calling-event's
    // (the calling input field's) name property and update the state's value to the <input>'s new value
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = async e => {
    e.preventDefault();
    const cookies = new Cookies();
    const loginData = JSON.stringify(this.state);

    await fetch("https://novid390.herokuapp.com/loginUser/", {
      method: "POST",
      body: loginData,
      headers: { "content-type": "application/json" },
    })
      .then(response => response.json())
      .then(jsondresponse => {
        cookies.set("userid", jsondresponse.userID);
        cookies.set("role", jsondresponse.role);
      })
      .catch(error => console.log(error));

    //REDIRECT DEPENDING ON THE ROLE THEY HAVE
    this.reDirectUserAfterLogin(Cookiesjs.get("role"));
  };

  reDirectUserAfterLogin = async typeOfUser => {
    if (typeOfUser === userRole.Patient) {
      this.props.history.push("/status-history");
    } else if (typeOfUser === userRole.Doctor) {
      this.props.history.push("/dashboard");
    } else if (typeOfUser === userRole.HealthOfficial) {
      this.props.history.push("/contact_report");
    } else if (typeOfUser === userRole.ImmigrationOfficer) {
      this.props.history.push("/search-patient");
    } else if (typeOfUser === userRole.Admin) {
      this.props.history.push("/view-users-admin");
    }
    //ADD MORE ELSE IFS FOR OTHER USERS
  };

  render() {
    return (
      <>
        <div className="drkbg-container">
          <Row>
            <Col lg={4} sm={0} />
            <Col lg={4} sm={12}>
              {/*The name property of every field needs to be the same as the state so that the relation is established */}
              <div className="auth-form-box">
                <form onSubmit={this.onSubmit} className="auth-form input">
                  <img className={"image-logo"} src={logo} alt="logo" />
                  <input name="email" type="text" placeholder="Email" onChange={this.onChange} />

                  <input name="password" type="password" placeholder="Password" onChange={this.onChange} />
                  <SubmitButton type="submit" classes="submit-button-padding" text="Login" />
                  <div>
                    <Link to={"/register"}>
                      <SubmitButton type="submit" text="Register" />
                    </Link>
                  </div>
                </form>
              </div>
            </Col>
            <Col lg={4} sm={0} />
          </Row>
        </div>
      </>
    );
  }
}

export default login;
