import { Row, Col, Form, Button, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/individuals_contact_tracing_form.css";
import { useState } from "react";
import DashBanner from "../../components/dash_banner";
import { useForm } from "react-hook-form";
import refreshPage from "../../components/refreshPage";
import GenericSideBar from "../../components/sidebar/GenericSideBar";

function EventContactTracingForm() {
  const [formName] = useState("Event/Store Contact Tracing Form");
  const { register, handleSubmit } = useForm();
  const [showSuccess, setSuccess] = useState(false);
  const onSubmit = data => {
    console.log(data);
    const eventContactData = JSON.stringify(data);

    fetch("https://novid390.herokuapp.com/createEventContact", {
      method: "POST",
      body: eventContactData,
      headers: { "content-type": "application/json" },
    })
      .then(response => response.json())
      .catch(error => console.log(error));

    console.log(data);
    setSuccess(true);
  };

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <DashBanner PageName={formName} />
        <Row>
          <Col lg={6}>
            <Card>
              <Card.Body className="cardBoxes">
                <Form id="eventContactForm" onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                    <Row>
                      <Form.Label className="nameTitles">Name of Event/Store</Form.Label>
                      <Col lg={8}>
                        <Form.Control {...register("eventName")} type="type" placeholder="Name of Event/Store" />
                      </Col>
                    </Row>

                    <Row>
                      <Form.Label className="nameTitles">Location</Form.Label>
                      <Col lg={8}>
                        <Form.Control {...register("location")} type="type" placeholder="Location" />
                      </Col>
                    </Row>

                    <Row>
                      <Form.Label className="nameTitles">Date of Visit</Form.Label>
                      <Col lg={8}>
                        <Form.Control {...register("date")} type="date" placeholder="date" />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
            <Button
              onClick={refreshPage}
              type="submit"
              form="eventContactForm"
              variant="flat"
              className="buttonColor submit_contact_form_button">
              Submit
            </Button>
            {showSuccess ? <Form.Label className="nameTitles">Event form successfully submitted.</Form.Label> : null}
            <br />
          </Col>
          <Col></Col>
        </Row>
      </div>
    </>
  );
}

export default EventContactTracingForm;
