import { React, useEffect, useState } from "react";
import "../../styles/app.css";
import { Col, Container, Row, Form, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import FirstNameLastNameFields from "../../components/registration/FirstNameLastNameFields";
import Password from "../../components/registration/password";
import Email from "../../components/registration/email";
import AddressGenderNumber from "../../components/registration/AddressGenderNumber";
import ContactPreference from "../../components/registration/contact_preference";
import SubmitButton from "../../components/registration/SubmitButton";
import DateOfBirth from "../../components/registration/DateOfBirth";
import RAMQ from "../../components/registration/RAMQ";
import GenericSideBar from "../../components/sidebar/GenericSideBar";
import DashBanner from "../../components/dash_banner";
import EmployeeId from "../../components/registration/EmployeeID";
import MedicalID from "../../components/registration/MedicalID";
import { ErrorMessage } from "@hookform/error-message";
import refreshPage from "../../components/refreshPage";

function CreateUser() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  //register("flag", { value: false });
  register("assignedDoctor", { value: "" });
  const onSubmit = data => {
    const userData = JSON.stringify(data);

    fetch("https://novid390.herokuapp.com/createUser", {
      method: "POST",
      body: userData,
      headers: { "content-type": "application/json" },
    });

    setSuccess(true);
  };
  const [showSuccess, setSuccess] = useState(false);
  //Allows to refresh the page only once the showSuccess has been changed which is after a successful register
  useEffect(() => {
    console.log(showSuccess);
    return refreshPage;
  }, [showSuccess]);
  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <Container fluid>
          <Row>
            <DashBanner PageName=""></DashBanner>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <h1>Create a User</h1>
            </Col>
          </Row>
          <Form id="RegistrationForm" onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <Card.Body className="cardBoxes">
                <Row className="mb-3">
                  <FirstNameLastNameFields register={register} errors={errors} />

                  <EmployeeId required={{ value: false }} register={register} errors={errors} />
                </Row>
                <Row className="mb-3">
                  <Col lg={6}>
                    <Password register={register} errors={errors} />
                  </Col>
                  <Col lg={6}>
                    <RAMQ required={{ value: false }} register={register} errors={errors} />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={6}>
                    <DateOfBirth register={register} errors={errors} />
                  </Col>
                  <Col lg={6}>
                    <Form.Group as={Col} controlId="">
                      <Form.Label>Type Of User</Form.Label>
                      <Form.Select
                        defaultValue="Choose..."
                        {...register("Roles", {
                          pattern: {
                            value: /^(?:Doctor|Health Official|Immigration Officer|Patient|Admin)$/,
                            message: "This field is required",
                          },
                        })}>
                        <option>Choose...</option>
                        <option value="Patient">Patient</option>
                        <option value="Doctor">Doctor</option>
                        <option value="Health Official">Health Official</option>
                        <option value="Immigration Officer">Immigration Officer</option>
                        <option value="Admin">Admin</option>
                      </Form.Select>
                      <ErrorMessage
                        errors={errors}
                        name="Roles"
                        render={({ message }) => <div style={{ color: "red" }}>{message}</div>}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={6}>
                    <Email register={register} errors={errors} />
                  </Col>
                  <Col lg={6}>
                    <MedicalID required={{ value: false }} register={register} errors={errors} />
                  </Col>
                </Row>

                <AddressGenderNumber register={register} errors={errors} />

                <ContactPreference register={register} errors={errors} />
              </Card.Body>
            </Card>
            <SubmitButton />
            {showSuccess ? <Form.Label className="nameTitles">New user successfully created.</Form.Label> : null}
          </Form>
        </Container>
      </div>
    </>
  );
}

export default CreateUser;
