import React from "react";
import "../styles/app.css";
import { Col, Container, Row, Form, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import FirstNameLastNameFields from "../components/registration/FirstNameLastNameFields";
import Password from "../components/registration/password";
import Email from "../components/registration/email";
import AddressGenderNumber from "../components/registration/AddressGenderNumber";
import ContactPreference from "../components/registration/contact_preference";
import SubmitButton from "../components/registration/SubmitButton";
import DateOfBirth from "../components/registration/DateOfBirth";
import RAMQ from "../components/registration/RAMQ";

function PatientRegistration() {
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  register("Roles", { value: "Patient" });
  register("flag", { value: false });
  register("assignedDoctor", { value: "" });
  const onSubmit = data => {
    const patientData = JSON.stringify(data);

    fetch("https://novid390.herokuapp.com/createUser", {
      method: "POST",
      body: patientData,
      headers: { "content-type": "application/json" },
    });

    history.push("/login");
  };
  return (
    <Container>
      <Row>
        <Col>
          <h1>Patient Register Form</h1>
        </Col>
      </Row>
      <hr />
      <Form id="RegistrationForm" onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Card.Body className="cardBoxes">
            <Row className="mb-3">
              <FirstNameLastNameFields register={register} errors={errors} />

              <RAMQ required={{ value: true, message: "This field is required" }} register={register} errors={errors} />
            </Row>
            <Row>
              <Col lg={6}>
                <Password register={register} errors={errors} />
              </Col>
              <Col lg={6}>
                <DateOfBirth register={register} errors={errors} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Email register={register} errors={errors} />
            </Row>

            <AddressGenderNumber register={register} errors={errors} />

            <ContactPreference register={register} errors={errors} />
          </Card.Body>
        </Card>
        <SubmitButton />
      </Form>
    </Container>
  );
}

export default PatientRegistration;
