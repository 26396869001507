import React, { useEffect } from "react";
import { Table, Container, Row } from "react-bootstrap";
import * as BiIcons from "react-icons/bi";
import "../../styles/app.css";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
// import Cookies from 'js-cookie';

import DashBanner from "../../components/dash_banner";
import axios from "axios";
import GenericSideBar from "../../components/sidebar/GenericSideBar";

function PatientStatusHistory() {
  const [stats, setStats] = useState([]);

  const [Patientid] = useState(useParams());
  const [pageName, setPageName] = useState("");

  useEffect(() => {
    //Setting the stats hook with information from the database
    axios
      .get("https://novid390.herokuapp.com/getStatus?patientID=" + Patientid._id, {})
      .then(response => response.data)
      .then(response => setStats(response))
      .catch(err => console.log(err));

    //Getting the patients name and creating the page name to pass to the dash component
    axios
      .post("https://novid390.herokuapp.com/getUserById/", {
        userid: Patientid,
      })
      .then(response => response.data)
      .then(response => setPageName(response.firstname + " " + response.lastname + "  : Status History"));
  }, [Patientid]);

  const setViewed = status => {
    console.log(status);
    axios
      .put("https://novid390.herokuapp.com/putStatusViewed?_id=" + status, {})
      .then(response => response.json())
      .catch(error => console.log(error));
  };

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <Container fluid>
          <Row>
            <div>
              <DashBanner PageName={pageName}></DashBanner>
            </div>
          </Row>
        </Container>

        <div>
          <Table hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Mood</th>
                <th>Weight</th>
                <th>Temperature</th>
                <th>
                  <BiIcons.BiSearchAlt />
                </th>
              </tr>
            </thead>

            <tbody>
              {stats.map((data, index) => (
                <tr key={data.id} className={data.viewed ? "viewed" : "unviewed"}>
                  <td> {data.date.slice(0, 10)} </td>
                  <td> {data.feel} </td>
                  <td> {data.weight} </td>
                  <td> {data.temperature} </td>
                  <td>
                    <Link to={`/patient-overview/${data._id}`} onClick={() => setViewed(data._id)}>
                      <BiIcons.BiSearchAlt />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default PatientStatusHistory;
