import React from "react";
import { Form, Col } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";

function EmployeeType(props) {
  return (
    <Form.Group as={Col} controlId="">
      <Form.Label>Type Of Employee</Form.Label>
      <Form.Select
        defaultValue="Choose..."
        {...props.register("Roles", {
          pattern: props.pattern,
        })}>
        <option>Choose...</option>
        <option value="Doctor">Doctor</option>
        <option value="Health Official">Health Official</option>
        <option value="Immigration Officer">Immigration Officer</option>
      </Form.Select>
      <ErrorMessage
        errors={props.errors}
        name="Roles"
        render={({ message }) => <div style={{ color: "red" }}>{message}</div>}
      />
    </Form.Group>
  );
}
export default EmployeeType;
