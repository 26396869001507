import React from "react";
import * as FaIcons from "react-icons/fa";

export const Sidebar_options_patients = [
  {
    title: "Appointments",
    path: "/patient-appointments",
    icon: <FaIcons.FaCalendarAlt />,
    className: "nav-text",
  },
  {
    title: "Add Status",
    path: "/add-status",
    icon: <FaIcons.FaUserPlus />,
    className: "nav-text",
  },
  {
    title: "Status History",
    path: "/status-history",
    icon: <FaIcons.FaHistory />,
    className: "nav-text",
  },
  {
    title: "Disclose Individual",
    path: "/contact_tracing_selector",
    icon: <FaIcons.FaUsers />,
    className: "nav-text",
  },
  {
    title: "Contact my Doctor",
    path: "/messenger",
    icon: <FaIcons.FaUserMd />,
    className: "nav-text",
  },
];
