import React from "react";
import * as BsIcons from "react-icons/bs";
import * as FaIcons from "react-icons/fa";

export const Sidebar_options_health = [
  {
    title: "Search Patient",
    path: "/search-patient",
    icon: <FaIcons.FaUserCog />,
    className: "nav-text",
  },
  {
    title: "Contact Tracing",
    // placeholder login -> contact trace summary .js ?
    path: "/contact_report",
    icon: <BsIcons.BsFillChatTextFill />,
    className: "nav-text",
  },
];
