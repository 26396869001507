import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import * as BiIcons from "react-icons/bi";
import "../../styles/app.css";
import { Link } from "react-router-dom";
import { useState } from "react";

import DashBanner from "../../components/dash_banner";
import axios from "axios";
import GenericSideBar from "../../components/sidebar/GenericSideBar";
import Cookies from "js-cookie";

function StatusHistory() {
  const [stats, setStats] = useState([]);
  const [Patientid] = useState(Cookies.get("userid"));

  useEffect(() => {
    //Setting the stats hook with information from the database
    axios
      .get("https://novid390.herokuapp.com/getStatusById?patientID=" + Patientid, {})
      .then(response => response.data)
      .then(response => setStats(response))
      .catch(err => console.log(err));
  }, [Patientid]);

  return (
    <>
      <GenericSideBar />

      <div className="page-container">
        <DashBanner PageName="Status History" />
        <div className="table">
          <Table hover>
            <thead>
              <tr>
                <th>Date</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {stats.map((data, index) => (
                <tr key={index}>
                  <td> {data.date.slice(0, 10)} </td>
                  <td>
                    <Link to={`/status-overview/${data._id}`}>
                      <BiIcons.BiSearchAlt />
                    </Link>
                  </td>
                  <td>
                    {index === 0 ? (
                      <Link to={`/update-status/${data._id}`}>
                        <BiIcons.BiEditAlt />
                      </Link>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default StatusHistory;
