import { Row, Col, Form, Button, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/individuals_contact_tracing_form.css";
import DashBanner from "../../components/dash_banner";
import { useState } from "react";
import { useForm } from "react-hook-form";
import refreshPage from "../../components/refreshPage";
import GenericSideBar from "../../components/sidebar/GenericSideBar";

function IndividualContactTracingForm() {
  const [formName] = useState("Individual Contact Tracing Form");
  const { register, handleSubmit } = useForm();
  const [showSuccess, setSuccess] = useState(false);
  const onSubmit = data => {
    console.log(data);
    const indContactData = JSON.stringify(data);

    fetch("https://novid390.herokuapp.com/createIndividualContact", {
      method: "POST",
      body: indContactData,
      headers: { "content-type": "application/json" },
    })
      .then(response => response.json())
      .catch(error => console.log(error));

    console.log(data);
    setSuccess(true);
  };

  return (
    <>
      <GenericSideBar />
      <div className="page-container">
        <DashBanner PageName={formName} />
        <Row>
          <Col lg={6}>
            <Card id="individualConctactCard">
              <Card.Body className="cardBoxes">
                <Form id="individualContactForm" onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                    <Row>
                      <Form.Label className="nameTitles">Individual Form</Form.Label>
                      <Form.Label className="nameTitles">Name</Form.Label>

                      <Col lg={6}>
                        <Form.Control {...register("firstName")} type="text" placeholder="First Name" />
                      </Col>
                      <Col lg={6}>
                        <Form.Control {...register("lastName")} type="text" placeholder="Last Name" />
                      </Col>
                    </Row>

                    <Form.Label className="nameTitles">Email</Form.Label>
                    <Form.Control {...register("email")} type="text" placeholder="Email" />

                    <Form.Label className="nameTitles"> Phone Number</Form.Label>
                    <Form.Control {...register("phoneNumber")} type="tel" placeholder="(000)000-0000" />

                    <Form.Label className="nameTitles"> Date Of Contact </Form.Label>
                    <Form.Control {...register("date")} type="date" placeholder="Last Name" />
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
            <Button
              onClick={refreshPage}
              type="submit"
              form="individualContactForm"
              variant="flat"
              className="buttonColor submit_contact_form_button">
              Submit
            </Button>
            {showSuccess ? (
              <Form.Label className="nameTitles">Individual contact successfully submitted.</Form.Label>
            ) : null}
            <br />
          </Col>
          <Col></Col>
        </Row>
      </div>
    </>
  );
}

export default IndividualContactTracingForm;
