import React from "react";
import { Button } from "react-bootstrap";

function SubmitButton(props) {
  return (
    <Button
      onClick={props.onClick}
      type="submit"
      form="RegistrationForm"
      variant="flat"
      className="buttonColor submit_contact_form_button">
      Register
    </Button>
  );
}
export default SubmitButton;
