import React, { useEffect, useState } from "react";

import "../../styles/app.css";

import { useParams } from "react-router-dom";
import FlaggingPatient from "../../components/status_page_components/flag";
// react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import DashBanner from "../../components/dash_banner";
import Symptoms from "../../components/status_page_components/symptoms";
import PrimaryStats from "../../components/status_page_components/prime_stats";
import GenericSideBar from "../../components/sidebar/GenericSideBar";

function PatientOverview() {
  const [statusId] = useState(useParams());
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    axios
      .get("https://novid390.herokuapp.com/getStatus?_id=" + statusId._id)
      .then(response => response.data)
      .then(response => setStatus(response[0]))
      .catch(err => console.log(err));

    //Getting the patients name
    axios
      .post("https://novid390.herokuapp.com/getUserById/", {
        userid: status.patientID,
      })
      .then(response => response.data)
      .then(response => [setName(response.firstname + " " + response.lastname), setFlag(response.flag)])
      .catch(err => console.log(err));
  }, [name, status.patientID, statusId._id]);

  if (!status && name.firstname == null) {
    return null;
  }

  return (
    <>
      <GenericSideBar />

      <div className="page-container">
        <Container fluid>
          <Row>
            <DashBanner></DashBanner>
          </Row>
          <Row>
            <Col md={6}>
              <div>
                <h4 className="nameTitles">{name}: Overview</h4>
              </div>
            </Col>
            <Col md={4}>
              <label className="inline TestedPostive">Tested Positive?</label>
              <label className="radio-inline yesRadio">
                {" "}
                Yes
                <input type="radio" disabled checked />
              </label>
              <label className="radio-inline noRadio">
                {" "}
                No
                <input type="radio" disabled />
              </label>
            </Col>
            <Col md={2}>
              <FlaggingPatient flag={flag} patientID={status.patientID} link="/patient-report" />
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <PrimaryStats
                temperature={status.temperature}
                weight={status.weight}
                feel={status.feel}
                comments={status.comments}
              />
            </Col>

            <Col md="4">
              <Symptoms allSymptoms={status.symptoms} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default PatientOverview;
